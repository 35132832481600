import React from "react";
import Pagetitle from "../elements/Pagetitle";

const brandlogoData = [
  {
    id: 1,
    image: "images/4691284_mongodb_icon.svg",
  },
  {
    id: 3,
    image: "images/4691303_mysql_icon.svg",
  },
  {
    id: 2,
    image: "images/4691292_react native_react_icon.svg",
  },
  {
    id: 5,
    image: "images/9118036_nextjs_fill_icon.png",
  },
  {
    id: 5,
    image: "images/4691465_flutter_icon.svg",
  },
  {
    id: 5,
    image: "images/9074220_typescript_icon.png",
  },
  {
    id: 4,
    image: "images/4691382_java_icon.svg",
  },
  {
    id: 6,
    image: "images/4691442_kotlin_icon.svg",
  },
  {
    id: 5,
    image: "images/8666226_golang_icon.svg",
  },
  {
    id: 5,
    image: "images/gcp.png",
  },
  {
    id: 5,
    image: "images/4691533_firebase_icon.svg",
  },
  {
    id: 5,
    image: "images/8666171_docker_icon.svg",
  },
  {
    id: 5,
    image: "images/4691305_rust_icon.svg",
  },
  {
    id: 5,
    image: "images/4691213_python_icon.svg",
  },
  {
    id: 5,
    image: "images/pytorch_logo_icon_144957.png",
  },
  {
    id: 5,
    image: "images/tensorflow.png",
  },
  {
    id: 5,
    image: "images/8547076_unity_icon.png",
  },
];

function Brandlogos() {
  return (
    <div id="branglogos">
      <div className="container">
        <Pagetitle title="Skills" />
        <div className="row">
          {brandlogoData.map((brandlogo) => (
            <div className="col-2 col-md-1" key={brandlogo.id}>
              <div className="client-item">
                <div className="inner">
                  <img
                    src={brandlogo.image}
                    alt="client-name"
                    className="object-cover"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Brandlogos;
