const NAME = {
  first: "Håvard",
  middle: "Stavnås",
  last: "Markhus",
};

const SOCIALS = {
  linkedin: "https://www.linkedin.com/in/haavasma/",
  github: "https://github.com/haavasma",
};

export { SOCIALS, NAME };
