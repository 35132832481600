import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import Portfolio from "../elements/Portfolio";

const filters = [
  {
    id: 1,
    text: "University Project",
  },
  // {
  //   id: 2,
  //   text: "Personal Projects",
  // },
  // {
  //   id: 3,
  //   text: "Data Science",
  // },
  // {
  //   id: 4,
  //   text: "Software Engineering",
  // },
];

const allData = [
  {
    id: 1,
    title: "Kvide Pain tracker - Repository",
    category: "University project",
    image:
      "https://github.com/tpk5100-pg05/kvide/blob/master/public/icon-512.png?raw=true",
    link: "https://github.com/tpk5100-pg05/kvide",
  },
  {
    id: 2,
    title: "Kvide Pain tracker",
    category: "University project",
    image:
      "https://github.com/tpk5100-pg05/kvide/blob/master/public/icon-512.png?raw=true",
    link: "https://kvide.eu",
  },
  {
    id: 3,
    title: "Bachelor Thesis",
    category: "University project",
    image:
      "https://ntnuopen.ntnu.no/ntnu-xmlui/bitstream/handle/11250/2778052/no.ntnu%3ainspera%3a83510435%3a83529121.pdf.jpg?sequence=4&isAllowed=y",
    link: "https://ntnuopen.ntnu.no/ntnu-xmlui/handle/11250/2778052",
  },
  {
    id: 4,
    title: "Video style transfer",
    category: "University project",
    image:
      "https://raw.githubusercontent.com/Haavasma/video-style-transfer/main/media/shrek_la_muse.gif",
    link: "https://github.com/Haavasma/video-style-transfer",
  },
  {
    id: 5,
    title: "AlphaZero for playing hex",
    category: "University project",
    image: "https://www.maths.ed.ac.uk/~csangwin/hex/Example5.png",
    link: "https://github.com/Haavasma/IT3105_Project_2",
  },
  {
    id: 6,
    title: "Online jenga",
    category: "University project",
    image:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.rent-event.co.uk%2Fwp-content%2Fuploads%2F2019%2F05%2Fjenga-1.jpg&f=1&nofb=1&ipt=e8b1f56cb8da9c570ccf2c36346a86e6a2b2e5a9e7cb5a27420106b17ad7944e&ipo=images",
    link: "https://github.com/Haavasma/3D_Graphics_Project",
  },
  {
    id: 7,
    title: "Two phase commit",
    category: "University project",
    image:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.gridgain.com%2Fsites%2Fdefault%2Ffiles%2F2018-01%2FFigure1.png&f=1&nofb=1&ipt=4a1271fb42be1ba3160464cf5bc786d353364b5e173220e47a3ff5a06bb8c944&ipo=images",
    link: "https://github.com/Haavasma/two-phase-commit",
  },
];

function Works() {
  const [getAllItems] = useState(allData);
  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(3);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);

  useEffect(() => {
    setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= 6));
  }, [getAllItems]);

  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
    let tempData;
    if (e.target.textContent.toLowerCase() === filters[0].text.toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = getAllItems.filter(
        (data) =>
          data.category === e.target.textContent.toLowerCase() &&
          data.id <= dataVisibleCount
      );
    }
    setVisibleItems(tempData);
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    if (dataVisibleCount > getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].text.toLowerCase()) {
        console.log("they are same");
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        setVisibleItems(
          getAllItems.filter(
            (data) => data.category === activeFilter && data.id <= tempCount
          )
        );
      }
    }
  };

  return (
    <section id="works">
      <div className="container">
        <Pagetitle title="Projects" />
        {/* Start Portfolio Filters */}
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <ul className="portfolio-filter list-inline">
            {filters.map((filter) => (
              <li className="list-inline-item" key={filter.id}>
                <button
                  onClick={handleChange}
                  className={
                    filter.text.toLowerCase() === activeFilter
                      ? "text-capitalize current"
                      : "text-capitalize"
                  }
                >
                  {filter.text}
                </button>
              </li>
            ))}
          </ul>
        </ScrollAnimation>
        {/* End Portfolio Filters */}

        {/* Start Portfolio Items */}
        <div className="row portfolio-wrapper">
          {visibleItems.map((item) => (
            <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
              <Portfolio portfolio={item} />
            </div>
          ))}
        </div>
        {/* End Portfolio Items */}

        <div className="load-more text-center mt-4">
          <button
            className="btn btn-default"
            onClick={handleLoadmore}
            disabled={noMorePost ? "disabled" : null}
          >
            {noMorePost ? (
              "No more items"
            ) : (
              <span>
                <i className="fas fa-spinner"></i> Load more
              </span>
            )}
          </button>
        </div>
      </div>
    </section>
  );
}

export default Works;
