import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "One year study - Games and Entertainment Technology",
    years: "2016-2017",
    content:
      "Study at Nord Universitet, Steinkjer. Learned basics of several " +
      "fields in game development: programming, 3D modelling, Sound engineering, " +
      "Game design.",
  },
  {
    id: 2,
    title: "Bachelor’s Degree in computer engineering",
    years: "2018 - 2021",
    content: "Degree in computer engineering at NTNU trondheim. 4.6/5.0 GPA. ",
  },
  {
    id: 3,
    title: "M.sc Computer science - Artificial Intelligence",
    years: "2021 - 2023",
    content:
      "2 year master's degree in computer science with specialty in " +
      "Artificial Intelligence.",
  },
];

const experienceData = [
  {
    id: 1,
    title: "Backend Software Engineer - Sportradar",
    years: "2021-2022",
    content:
      "Worked as an intern Java backend developer at the NASDAQ noted " +
      "Trondheim-based company Sportradar. Helped migrate Java API to " +
      "cloud as well as improving the performance of the API.  " +
      "Pushed features to high-stakes video technology API within a larger backend infrastructure",
  },
  {
    id: 2,
    title: "Data Science intern - Sportradar",
    years: "2022 - 2022",
    content:
      "Single-handedly, but under guidance from mentor, " +
      "developed a robust algorithm for detecting malicious sports tournament traffic. " +
      "Iterated on and evaluated the developed system to confidently find the  " +
      "most suspicious cases where illegal restraming might be involved. ",
  },
  {
    id: 3,
    title: "Full-stack developer - Favn Software",
    years: "2020 - now",
    content:
      "Developed features for several consultant projects involving the " +
      "development of end-to-end web-applications and mobile applications. " +
      "Served as technical lead for an in-house ecommerce platform project, which " +
      "is planned to take part as a module in a larger project. " +
      "Currently working part-time in the core team developing the system for vipir.no",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
