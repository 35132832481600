import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Homepage2Dark from "./pages/Homepage2Dark";
import "./App.scss";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Håvard Stavnås Markhus - Portfolio</title>
              <meta
                name="description"
                content="Håvard Stavnås Markhus, an aspiring Software engineer/developer and 
              data scientist"
              />
            </Helmet>
            <Homepage2Dark />
          </Route>
        </Switch>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
